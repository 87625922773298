import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

export const url = "api/v1/InsuranceCompany";

export async function searchInsuranceCompanys(request) {
  return await ApiService.post(url + "/search", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function searchDictInsuranceCompanys(request) {
  return await ApiService.post(url + "/searchDict", request as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function addInsuranceCompany(InsuranceCompany) {
  return await ApiService.post(url, InsuranceCompany as never)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function getInsuranceCompany(InsuranceCompanyId) {
  return await ApiService.get(url + "/" + InsuranceCompanyId)
    .then((res) => {
      const item = res.data;
      return item;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateInsuranceCompany(InsuranceCompany) {
  if (InsuranceCompany) {
    return await ApiService.put(
      url + "/" + InsuranceCompany.id,
      InsuranceCompany as never
    )
      .then((res) => {
        return res;
      })
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

export async function deleteInsuranceCompany(InsuranceCompany) {
  if (InsuranceCompany) {
    await ApiService.delete(url + "/" + InsuranceCompany?.id)
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}
